<template>
  <div>
      <v-card
       class="mx-auto mb-5 v-card v-sheet theme--light"
       style="background-color:#4d81c0 !important"
      :style="$vuetify.breakpoint.mobile ? 'max-width: 344px;' : ''">
            <v-card-title class="white--text"><v-icon color="white"
        large
        left
      >
        mdi-book-open-variant
      </v-icon>Часопис</v-card-title>
      <v-card-text>
          <v-card
      :style="$vuetify.breakpoint.mobile ? 'max-width: 374px;' : ''"
      class="mx-auto pa-3"
    >
      <v-img
        src="../assets/AMH logo.jpg"
        :aspect-ratio="16/9"
      ></v-img>

      <v-card-actions class="mt-5">
        <a href="https://www.sldprokuplje.rs/amh/" target="_blank"><v-btn color="primary" dark> Отвори </v-btn></a> 
      </v-card-actions>

    </v-card>
      </v-card-text>
      </v-card>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    show: false,
  }),
};
</script>

<style>
</style>